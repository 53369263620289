// @ts-ignore
import getCookie from 'browser/utils/getCookie'

type EventType = Record<string, string>

const deleteVisitorIdRecords = () => {
  document.cookie = 'm_vis_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
}

const saveVisitorId = (visitorId: string) => {
  document.cookie = `m_vis_id=${visitorId}; path=/na-okrzei; domain=matexipolska.pl; session=true; httpOnly=false; secure=false; SameSite=unspecified;`
}

export const removeDuplicateVisitorIdAndSave = () => {
  const visitorId = getCookie('m_vis_id')
  const cookiebotElement = document.getElementById('CybotCookiebotDialog')

  if (cookiebotElement) {
    // Detect when cookiebotElement is removed from the DOM
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutationRecord) {
        setTimeout(() => {
          deleteVisitorIdRecords()
          saveVisitorId(visitorId)
        }, 2000)
      })
    })
    observer.observe(cookiebotElement, {
      attributes: true,
      attributeFilter: ['style'],
    })

    return
  }

  deleteVisitorIdRecords()
  saveVisitorId(visitorId)
}

export const getVisitorId = () => getCookie('m_vis_id') || ''

export const eventsNames = {
  // click events
  click_wybierz_mieszkanie: 'click_wybierz_mieszkanie',
  click_ulubione_wyszukiwarka_dodaj: 'click_ulubione_wyszukiwarka_dodaj',
  click_ulubione_wyszukiwarka_usun: 'click_ulubione_wyszukiwarka_usun',
  click_ulubione_karta_dodaj: 'click_ulubione_karta_dodaj',
  click_ulubione_karta_usun: 'click_ulubione_karta_usun',
  click_ulubione_lista_dodaj: 'click_ulubione_lista_dodaj',
  click_ulubione_lista_usun: 'click_ulubione_lista_usun',
  click_pobierz_pdf: 'click_pobierz_pdf',
  click_prospekt_informacyjny: 'click_prospekt_informacyjny',
  click_mieszkanie_karta: 'click_mieszkanie_karta',
  click_powiekszenie: 'click_powiekszenie',
  click_pietro_zmiana_wyszukiwarka: 'click_pietro_zmiana_wyszukiwarka',
  click_pietro_zmiana_karta: 'click_pietro_zmiana_karta',
  click_pietro_wyszukiwarka: 'click_pietro_wyszukiwarka',
  click_numer_kondygnacji_wyszukiwarka: 'click_numer_kondygnacji_wyszukiwarka',
  click_mieszkanie_wyszukiwarka: 'click_mieszkanie_wyszukiwarka',
  click_spacer_3d_wyszukiwarka: 'click_spacer_3d_wyszukiwarka',
  click_spacer_3d_karta: 'click_spacer_3d_karta',
  click_social_media: 'click_social_media',
  click_telefon: 'click_telefon',
  click_lista_mieszkanie: 'click_lista_mieszkanie',

  // CONTACT FORM TOP
  formularz_gora_wypelnianie_start: 'formularz_gora_wypelnianie_start',
  formularz_gora_wyslany: 'formularz_gora_wyslany',
  formularz_gora_blad_wysylki: 'formularz_gora_blad_wysylki',
  // CONTACT FORM BOTTOM
  formularz_dol_wypelnianie_start: 'formularz_dol_wypelnianie_start',
  formularz_dol_wyslany: 'formularz_dol_wyslany',
  formularz_dol_blad_wysylki: 'formularz_dol_blad_wysylki',
  // FLAT FORM
  formularz_mieszkanie_wypelnianie_start:
    'formularz_mieszkanie_wypelnianie_start',
  formularz_mieszkanie_wyslany: 'formularz_mieszkanie_wyslany',
  formularz_mieszkanie_blad_wysylki: 'formularz_mieszkanie_blad_wysylki',
  formularz_mieszkanie_wyswietlenie: 'formularz_mieszkanie_wyswietlenie',
  // RESERVATION FORM
  formularz_rezerwacja_wypelnianie_start:
    'formularz_rezerwacja_wypelnianie_start',
  formularz_rezerwacja_wyslany: 'formularz_rezerwacja_wyslany',
  formularz_rezerwacja_blad_wysylki: 'formularz_rezerwacja_blad_wysylki',
  formularz_rezerwacja_wyswietlenie: 'formularz_rezerwacja_wyswietlenie',
  // PROMOTIONS
  click_promocja_otwarcie: 'click_promocja_otwarcie',
  click_promocja_zamkniecie: 'click_promocja_zamkniecie',
  click_ulubione_karta_promocja_dodaj: 'click_ulubione_karta_promocja_dodaj',
  click_ulubione_karta_promocja_usun: 'click_ulubione_karta_promocja_usun',
  click_spacer_3d_karta_promocja: 'click_spacer_3d_karta_promocja',
  click_mieszkanie_karta_promocja: 'click_mieszkanie_karta_promocja',
  click_lista_mieszkanie_promocja: 'click_lista_mieszkanie_promocja',
  click_ulubione_lista_promocja_dodaj: 'click_ulubione_lista_promocja_dodaj',
  click_ulubione_lista_promocja_usun: 'click_ulubione_lista_promocja_usun',
  click_pietro_zmiana_karta_promocja: 'click_pietro_zmiana_karta_promocja',
}

const emitMatomoEvent = (event: EventType) => {
  const mtmEvent = {
    ...event,
    visitorId: getVisitorId(),
  }

  if (typeof window !== `undefined`) {
    // @ts-ignore
    if (window._mtm) {
      // @ts-ignore
      window._mtm.push(mtmEvent)
    }
  }
}

export default emitMatomoEvent
