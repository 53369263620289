import type { Breakpoints, Colors, Fonts, Container } from 'types/theme'

import media from 'styles/media'

export const breakpoints: Breakpoints = {
  xxs: 0,
  xs: 360,
  s: 440,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1601,
}

export const colors: Colors = {
  primary: '#C9BEB2',
  primary100: '#E9E4E0',
  primary200: '#AE9675',
  primary300: '#6D3113',
  dark: '#2B363A',
  dark100: '#565655',
  dark200: '#4A4A49',
  black: '#000000',
  black100: '#5e5959',
  black200: '#959090',
  black300: '#4A4A49',
  gray: '#E6EAEE',
  gray100: '#CDCDCD',
  white: '#FFFFFF',
  danger: '#CA0F0F',
}

export const fonts: Fonts = {
  size: '10px',
}

export const container: Container = {
  widthXL: '1300px',
  width: '1300px',

  paddingMD: '2rem',
  paddingSM: '1rem',
  padding: '2rem',
}

export const navbar = {
  height: '90px',
  heightLG: '100px',
}

const theme = {
  breakpoints,
  colors,
  fonts,
  container,
  navbar,
  media: media(),
}

export default theme
