const getCookie = (name) => {
  if (typeof window !== `undefined`) {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))
    if (match) return match[2]
    return ''
  }

  return ''
}

export default getCookie
